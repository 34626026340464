import React from "react"
import Slider from "react-slick"
import "./carousel.css"
import { graphql, useStaticQuery } from "gatsby"

const NextArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "40px",
        zIndex: "1",
        fontSize: "40px !important",
        top: "45%",
      }}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      role="button"
    />
  )
}

const PrevArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "15px",
        zIndex: "1",
        fontSize: "40px !important",
        top: "45%",
      }}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={-1}
      role="button"
    />
  )
}

const OurTeamCarousel = () => {
  const pageData = useStaticQuery(graphql`
    query {
      contentfulNavigationPages(idDontChange: { eq: "aboutus" }) {
        images{
          file {
            url
            fileName
            contentType
          }
        }
      }
    }
  `)

  const data = pageData?.contentfulNavigationPages?.images||[]
  const slides = data?.map(item=>{
    return {img:item?.file?.url,alt:item?.file?.fileName}
  })
  // [
  //   { img: team1, alt: "team1" },
  //   { img: team2, alt: "team2" },
  //   { img: team3, alt: "team3" },
  //   { img: team4, alt: "team4" },
  //   { img: team5, alt: "team5" },
  // ]

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    lazyLoad: true,
    autoplay: true,
    // speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  return (
    <Slider {...settings}>
      {slides.map((slide, i) => (
        <div className="item" key={i}>
          <div className="testimonial_content">
            <img
              src={slide.img}
              alt={slide.alt}
              className="lazyload quotes_icon"
            />
          </div>
        </div>
      ))}
    </Slider>
  )
}

const OurTeam = () => {
  return (
    <div className="bg_testimonial padding ptb-xs-40">
      <div className="container">
        <div className="row pb-50 pb-xs-30 text-center">
          <div className="col-md-12">
            <div className="section-title_home light-color">
              <p>team members?</p>
              <h2>
                Our <span>Team</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="testimonial_show">
              <div id="testimonial_slider" className="testimonial_slider">
                <OurTeamCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurTeam
