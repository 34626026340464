import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, useStaticQuery, graphql } from "gatsby"

import About from "../components/about.component"
import OurTeam from "../components/ourteam.component"
import Partners from "../components/partners.component"

const AboutPage = () => {
  const pageData = useStaticQuery(graphql`
    query {
      contentfulNavigationPages(idDontChange: { eq: "aboutus" }) {
        name
        seo {
          title
          description
          keywords
        }
        slug
        hiddenContent {
          childMarkdownRemark {
            html
          }
        }
        bannerImage {
          fluid {
            src
          }
        }
        contentImage {
          fluid {
            src
          }
        }
      }
    }
  `)

  const data = pageData.contentfulNavigationPages
  return (
    <Layout>
      <SEO
        title={data.seo.title}
        description={data.seo.description}
        keywords={data.seo.keywords}
      />
      <div>
        <section
          className="inner-intro bg-img light-color overlay-before parallax-background"
          style={{
            background: `url(${data.bannerImage.fluid.src}) 0 58% no-repeat`,
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row title">
              <div className="title_row">
                <h1 data-title="About">
                  <span>About</span>
                </h1>
                <div className="page-breadcrumb">
                  <Link to="/">Home</Link> / <span>About</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="about-section" className="padding pt-xs-40">
          <div className="container">
            <div className="row text-center mb-40">
              <div className="col-lg-6 offset-lg-3 sect-title">
                <h2>
                  <span>About</span> Us
                </h2>
                <span className="title_bdr">
                  <i className="ion-more" />
                </span>
              </div>
            </div>
            <div className="row">
              <About />
            </div>
          </div>
        </div>
        {/* Story Section */}
        <div id="story-section" className="ptb ptb-xs-60 gray-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="ui-timline-container">
                  <div className="ui-timeline">
                    <div className="tl-item">
                      <div className="tl-body">
                        <div className="tl-entry">
                          <div className="tl-caption">
                            <div className="btn btn-primary btn-block">
                              FEATURES
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tl-item">
                      <div className="tl-body">
                        <div className="tl-entry">
                          <div className="tl-icon btn-icon-round btn-icon btn-icon-thin btn-info">
                            <i className="fa fa-asterisk" />
                          </div>
                          <div className="tl-content">
                            <h4 className="tl-tile text-primary">
                              FREE VISIT AND QUOTE
                            </h4>
                            <p>
                              We provide free site visit and quotation to our
                              customers.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tl-item alt">
                      <div className="tl-body">
                        <div className="tl-entry">
                          <div className="tl-icon btn-icon-round btn-icon btn-icon-thin btn-warning">
                            <i className="fa fa-shopping-cart" />
                          </div>
                          <div className="tl-content">
                            <h4 className="tl-tile text-danger">
                              EXPERT TECHNICIANS
                            </h4>
                            <p>
                              Our technicians have vast expertise and are highly
                              skilled to resolve all indoor shades, outdoor
                              shades, upholstery and soft furnishing issues.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tl-item">
                      <div className="tl-body">
                        <div className="tl-entry">
                          <div className="tl-icon btn-icon-round btn-icon btn-icon-thin btn-success">
                            <i className="fa fa-camera" />
                          </div>
                          <div className="tl-content">
                            <h4 className="tl-tile text-warning">
                              NO HIDDEN COST
                            </h4>
                            <p>
                              We do not include any hidden or additional charges
                              without the consent of our clients.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tl-item alt">
                      <div className="tl-body">
                        <div className="tl-entry">
                          <div className="tl-icon btn-icon-round btn-icon btn-icon-thin btn-danger">
                            <i className="fa fa-check" />
                          </div>
                          <div className="tl-content">
                            <h4 className="tl-tile text-success">
                              AFFORDABLE PRICES
                            </h4>
                            <p>
                              We provide the best blinds, curtains, upholstery,
                              CNC, laser-cutting and all other residential and
                              commercial decor solutions at affordable prices.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Partners />
        <OurTeam />
      </div>
      <div
        className="container"
        style={{ display: "none" }}
        dangerouslySetInnerHTML={{
          __html: data.hiddenContent
            ? data.hiddenContent.childMarkdownRemark.html
            : "",
        }}
      ></div>
    </Layout>
  )
}

export default AboutPage
